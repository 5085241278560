import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/Logitech_Harmony/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Logitech Harmony in ioBroker via INSTAR MQTT",
  "path": "/Software/Home_Automation/Logitech_Harmony/ioBroker/",
  "dateChanged": "2020-03-11",
  "author": "Mike Polinowski",
  "excerpt": "Logitech Harmony",
  "social": "/images/Search/P_SearchThumb_Harmony.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Harmony_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "ioBroker"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Logitech Harmony in ioBroker via INSTAR MQTT' dateChanged='2020-03-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Logitech Harmony, ioBroker and INSTAR MQTT' image='/images/Search/P_SearchThumb_Harmony.png' twitter='/images/Search/P_SearchThumb_Harmony.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/Logitech_Harmony/ioBroker/' locationFR='/fr/Home_Automation/Logitech_Harmony/ioBroker/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <h3 {...{
      "id": "logitech-harmony-with-iobroker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#logitech-harmony-with-iobroker",
        "aria-label": "logitech harmony with iobroker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logitech Harmony with ioBroker`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-the-fakeroku-adapter-in-iobroker"
        }}>{`Install the Fakeroku Adapter in ioBroker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instar-mqtt-setup"
        }}>{`INSTAR MQTT Setup`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#pan--tilt-your-camera"
            }}>{`Pan & Tilt your Camera`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#arm-your-alarm-before-your-leave-your-home"
            }}>{`Arm your Alarm before your leave your Home`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#start-a-manual-video-recording"
            }}>{`Start a Manual Video Recording`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#trigger-an-alarm"
            }}>{`Trigger an Alarm`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#blockly-json-export"
        }}>{`Blockly JSON Export`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "install-the-fakeroku-adapter-in-iobroker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#install-the-fakeroku-adapter-in-iobroker",
        "aria-label": "install the fakeroku adapter in iobroker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install the Fakeroku Adapter in ioBroker`}</h2>
    <p>{`Switch to the adapter tab and search for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fakeroku`}</code>{` to find and install the `}<strong parentName="p">{`Fakeroku Emulator`}</strong>{`. Once installed configure the adapter to use your host machines IPv4 interface (Do not leave it a 0.0.0.0 !) - the adapter will display your hosts IP address. In my case this is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{`. You can leave the multicast IP, device name and service port at their default values:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "854px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/878ecdb7537f36d46743e1198afc75aa/c3fd4/ioBroker_Logitech_Harmony_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABn0lEQVQY01XGMW/TQACGYf89JCoEa4fCwNAOtBNFnTt0AESHLkAhE2qRCGkTwIlzsQ1FAZ3d+JzYudSpHVInwufYPifnC1I9oHRA4dWjT5+wWcuegGyrlu2A6WHd0u1LzXY1+7KD+69/hOtf+GY1e7TkMeDbjfm2NN2pXAkbIr9f5munbO2UPTih65/Sh+V09WO6WqT3jpPbR3Tlf3eO6d339Na7ZKMcCS9Ee69iHYD+QcN9Vr3Yl9zntf5uubcnuocwKbSmhTYv6Ok/b3Va0OkrSD93U0F681KrlOJwHPoe8T0yGkzGA3LVj8aDnNPr+Pc1bufpJM9ozpJlf2aJ8AtjD/d83w8J8Vy3h/FwOJwQQoIgnrGgi8On+5E/itI0iuIoTpYJyPM1x0Pu0ByMLD/AQXxBqBPNvHjGWMbnc57ni80W8aUYYwLqWLBl/NTPm1CDBkJ2VzNM2EJaC7U7HcuyTMMw0eLbto0QMm9CCDmOI3w/+1YsfqiKoqooX1VF16CiKA0AZFmWJKlUKsmyDABQVbXZbAIAGjfV63UI4V9orIHZjaMbBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/878ecdb7537f36d46743e1198afc75aa/e4706/ioBroker_Logitech_Harmony_01.avif 230w", "/en/static/878ecdb7537f36d46743e1198afc75aa/d1af7/ioBroker_Logitech_Harmony_01.avif 460w", "/en/static/878ecdb7537f36d46743e1198afc75aa/e6df6/ioBroker_Logitech_Harmony_01.avif 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/878ecdb7537f36d46743e1198afc75aa/a0b58/ioBroker_Logitech_Harmony_01.webp 230w", "/en/static/878ecdb7537f36d46743e1198afc75aa/bc10c/ioBroker_Logitech_Harmony_01.webp 460w", "/en/static/878ecdb7537f36d46743e1198afc75aa/a7c35/ioBroker_Logitech_Harmony_01.webp 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/878ecdb7537f36d46743e1198afc75aa/81c8e/ioBroker_Logitech_Harmony_01.png 230w", "/en/static/878ecdb7537f36d46743e1198afc75aa/08a84/ioBroker_Logitech_Harmony_01.png 460w", "/en/static/878ecdb7537f36d46743e1198afc75aa/c3fd4/ioBroker_Logitech_Harmony_01.png 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/878ecdb7537f36d46743e1198afc75aa/c3fd4/ioBroker_Logitech_Harmony_01.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now switch back to the `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Logitech_Harmony/"
      }}>{`general tutorial to add the virtual device to your Logitech Harmony Hub`}</a>{`.`}</p>
    <h2 {...{
      "id": "instar-mqtt-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-mqtt-setup",
        "aria-label": "instar mqtt setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Setup`}</h2>
    <p>{`The Fakeroku virtual device will now give you access to the following buttons on your Harmony Remote:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b851e5d6852546acbb0fb81e37a93be4/4b446/ioBroker_Logitech_Harmony_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVQoz32ST0/CQBDFZ3e2KIqQYpC2mJZCi6X89eIBKMaIwXjw4vf/LM/sZtsoVA8vszPd/mbeZoiI4LBCLxxg//mBXr8PXRNCmPhTbSFxY+vC5q6QuPp9l9BwGhjNczxtN3g7HNBsNs9gWhErTJRTAW+lRKYc+JJ/A7XcdgeB7yOMIqTjBPIExkS4k2yglyRMrkFDVqeN7UEIxMMYQz/Ay2KJOAyrSXSUFjiQbGDCAhPlmDqfAYnQarWw32xxXK7wtdkhcN3qmyIysJiViTr3JBvLQZ3lUkmS4lg843X9iPf5AtfWkgbcaxizebsLEujbBt5/QMWMoigwyTLM8imS0biyrH/UsLJBR8iqQS2wXBXf87Df7hCFEfJpDrfbNW8WKQcz1TCQui2onbBUmk6wXq2RPWTVGukptVX5Nwzfp/jJmpeAresAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b851e5d6852546acbb0fb81e37a93be4/e4706/ioBroker_Logitech_Harmony_02.avif 230w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/d1af7/ioBroker_Logitech_Harmony_02.avif 460w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/0a597/ioBroker_Logitech_Harmony_02.avif 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b851e5d6852546acbb0fb81e37a93be4/a0b58/ioBroker_Logitech_Harmony_02.webp 230w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/bc10c/ioBroker_Logitech_Harmony_02.webp 460w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/d7666/ioBroker_Logitech_Harmony_02.webp 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b851e5d6852546acbb0fb81e37a93be4/81c8e/ioBroker_Logitech_Harmony_02.png 230w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/08a84/ioBroker_Logitech_Harmony_02.png 460w", "/en/static/b851e5d6852546acbb0fb81e37a93be4/4b446/ioBroker_Logitech_Harmony_02.png 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b851e5d6852546acbb0fb81e37a93be4/4b446/ioBroker_Logitech_Harmony_02.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For example `}<strong parentName="p">{`Button 1`}</strong>{` will send the payload string `}<strong parentName="p">{`Rev`}</strong>{` to Node-RED when you press it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "854px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e1e199a9370cab68f95bb8beed4dc44/c3fd4/ioBroker_Logitech_Harmony_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACGUlEQVQoz12QTW/UMBCG81vplVaIijOinDjxceDKb0CASoGVEIduVXFpt81m011vHHtsJ45n7GzS7ccB5A0U1NHo1Uied+bxJNv73ZOv3fbn1e6X1e4nuzdq9kbu2Tf3dISPDlYPPnRbH7utQTe5c9DvHPQP91eP34vk7ZF/8cO/Gft3P/3rw/DqMDz/Hl6O2+O8Pl/Wp8JPBJ0NKv0Jd+NUHGVynInjXCcNoQ29DX3o1qpGqFE3XlPX5+frIr+5vry5XF337e26u1z523V31cX61+3VVd8maFJXSbK2b/10mrF8xuc5Xyw456aqiAidQ+cIHeJQILoYdV0nmWRnsjoDnFbtUcpS46d1G9P4aeVTE1Lj0+pOY06Nz7S70E0iFOdqCZa080yo6bwAi9r5vBBMaoNBNaQaAotDoRqvLOqGoLIJYQOCaQWrNggJxZIFT8ETL5YFY/OLXJQ8+EgfPFVGKwBPrrG1tTYhIqU0KOW9B62llLQJIWUpymyWFZwTkXNu6BQSiKhpXF3bBBGVUgqiWWkjxMaMJEFKKeaLuRDizmyMAVDxHTEejIikFAAQQhBSDq1EtFwWS8ZOT0/m88VgjmgAnHNEtHVd/8UGY4z3XkYGtfGiEKIsy1k+4/9h6/ivAbuJ2JvNsizLOFjpoig2XgQAkJKxxT3sP9P/YQvBOQ8hlBIYYwN2UUTsyWSyWNzDLiO2jef+DafTsxSo/bmuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e1e199a9370cab68f95bb8beed4dc44/e4706/ioBroker_Logitech_Harmony_03.avif 230w", "/en/static/5e1e199a9370cab68f95bb8beed4dc44/d1af7/ioBroker_Logitech_Harmony_03.avif 460w", "/en/static/5e1e199a9370cab68f95bb8beed4dc44/e6df6/ioBroker_Logitech_Harmony_03.avif 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e1e199a9370cab68f95bb8beed4dc44/a0b58/ioBroker_Logitech_Harmony_03.webp 230w", "/en/static/5e1e199a9370cab68f95bb8beed4dc44/bc10c/ioBroker_Logitech_Harmony_03.webp 460w", "/en/static/5e1e199a9370cab68f95bb8beed4dc44/a7c35/ioBroker_Logitech_Harmony_03.webp 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e1e199a9370cab68f95bb8beed4dc44/81c8e/ioBroker_Logitech_Harmony_03.png 230w", "/en/static/5e1e199a9370cab68f95bb8beed4dc44/08a84/ioBroker_Logitech_Harmony_03.png 460w", "/en/static/5e1e199a9370cab68f95bb8beed4dc44/c3fd4/ioBroker_Logitech_Harmony_03.png 854w"],
              "sizes": "(max-width: 854px) 100vw, 854px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e1e199a9370cab68f95bb8beed4dc44/c3fd4/ioBroker_Logitech_Harmony_03.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Press all available buttons and refresh the ioBroker `}<strong parentName="p">{`Object Tree`}</strong>{` to see the objects that we can now start to work with:`}</p>
    <ol>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Rev`}</code>{` - `}<em parentName="li">{`Unused`}</em></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Play`}</code>{` - Start a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`x`}</code>{` Second `}<strong parentName="li">{`Video Recording`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Fwd`}</code>{` - `}<em parentName="li">{`Unused`}</em></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Home`}</code>{` - Deactivate the `}<strong parentName="li">{`Alarm`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Up`}</code>{` - Move Camera `}<strong parentName="li">{`Up`}</strong>{` by one Step`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Right`}</code>{` - Move Camera `}<strong parentName="li">{`Right`}</strong>{` by one Step`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Down`}</code>{` - Move Camera `}<strong parentName="li">{`Down`}</strong>{` by one Step`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Left`}</code>{` - Move Camera `}<strong parentName="li">{`Left`}</strong>{` by one Step`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Select`}</code>{` - Stop Camera Movement`}</li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Back`}</code>{` - Activate the `}<strong parentName="li">{`Alarm`}</strong></li>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Info`}</code>{` - Trigger enabled Alarm Actions`}</li>
    </ol>
    <p>{`We now need to add an adapter that allows us to write a script so we can trigger `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`an MQTT update for our camera`}</a>{` when we receive a message from our Harmony Remote. I would recommend to use the `}<strong parentName="p">{`Node-RED Adapter`}</strong>{` in ioBroker. Once you have it set up you can `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/Logitech_Harmony/Node-RED/"
      }}>{`continue using our Node-RED tutorial`}</a>{` to connect your camera (see also `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/#node-red-adapter"
      }}>{`INSTAR MQTT & Node-RED`}</a>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "856px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8837a58d47dec038773d26b42a8a333b/ad12c/ioBroker_Logitech_Harmony_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB7ElEQVQozzXMPW/TQACAYUv9K/wLNn4HGwNhIVURlQoTK0MrkQEWEBQkFhBVaFqIQksLNI2J82EntXNn353vfHdOE8ffDqKqjKjgGd/hVdaa6cODdL2Vb3xJnzQnrT5uDcjnPjoakdpJcKuxvLNfVPayym5y+2NcaaRrzexeM1v9FN+vM2Xze1jdj+7uLR40g7e9AHjzsRdZcK4f/nTUAVH7sKuf4elExnCeWzy0WGB5gckCky2UrQN+c9uuNoJDEIt5LIM0iBYoHPjn+nI2Xc78YiaLNIo45Z2T5Fwus6SIwzyJ8iRUHr/rXKu+qcPfu6T8YF/WcXlq8xu16+vvVy8vyiTP8yy7KEv16bPNlRX09ehXWaZptizyOI6VRhdtvP5xjItjXHxDae00aVti6+WjF/XneZJnUTDEs51xxIf9zqttiXGUJEkY7AznAzxTfMkpgsAygWV6lHTH0PWE5NJFxLQsRl0TIu3MkVPfoXQCgOM4jFJ1BG3iKR7nCBPXpcR1XUoFZ76UiGCE0VWgHqO+8Djn2HFcQugVztypLxXP89rttqqqWq+HENINQwjJGKP//V0wJgS/Wv1DiCulVBhjakfVupqmabZtQwillACAQX9gGAYAwIbQtm0hhKHrum6MRiOEHNO0OOd/AJt+wdhBB/qhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8837a58d47dec038773d26b42a8a333b/e4706/ioBroker_Logitech_Harmony_04.avif 230w", "/en/static/8837a58d47dec038773d26b42a8a333b/d1af7/ioBroker_Logitech_Harmony_04.avif 460w", "/en/static/8837a58d47dec038773d26b42a8a333b/808e8/ioBroker_Logitech_Harmony_04.avif 856w"],
              "sizes": "(max-width: 856px) 100vw, 856px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8837a58d47dec038773d26b42a8a333b/a0b58/ioBroker_Logitech_Harmony_04.webp 230w", "/en/static/8837a58d47dec038773d26b42a8a333b/bc10c/ioBroker_Logitech_Harmony_04.webp 460w", "/en/static/8837a58d47dec038773d26b42a8a333b/e2cd0/ioBroker_Logitech_Harmony_04.webp 856w"],
              "sizes": "(max-width: 856px) 100vw, 856px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8837a58d47dec038773d26b42a8a333b/81c8e/ioBroker_Logitech_Harmony_04.png 230w", "/en/static/8837a58d47dec038773d26b42a8a333b/08a84/ioBroker_Logitech_Harmony_04.png 460w", "/en/static/8837a58d47dec038773d26b42a8a333b/ad12c/ioBroker_Logitech_Harmony_04.png 856w"],
              "sizes": "(max-width: 856px) 100vw, 856px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8837a58d47dec038773d26b42a8a333b/ad12c/ioBroker_Logitech_Harmony_04.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Alternatively`}</strong>{`, you can install the `}<strong parentName="p">{`Script Engine Adapter`}</strong>{` and use `}<strong parentName="p">{`Blockly`}</strong>{` to do the work:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec1913d42d8e8d8eaa115800bebf2282/dd104/ioBroker_Logitech_Harmony_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABZklEQVQY00WQTS8DURSG57/YW/hBdsLC0k4QG+IjESVIEGFm2pm5bYkfgG0nYsKiNEFbtETNnXunM3P7de8ro8RZnpwn73Ne7f3T5+0IQKFQyGQyhJBsLpez7JxFHFIkpEjyRdu2LctySN4hxHGIZVm2bRuGoT2/NttxAsA0zVKpBCARQsSsE1NAAn1A4XckMACU+llwzrXGR4vxcAi7bgpLhcgbZxcj9Grsy5tJHlfy2Z3ZhaX5jePFrezc+kEQBAB839eqzVaLpdq6Ybg/yQMJ5k3Ry1HuTdDKXvx2el44Wl7bXN0n64fFpW2dcw6AUqqd3HX0245Zwcqu7l27abKUIfMbb9Xy/cNLvc5Y0Ov1RJI0X2ucUyX7Q+8gCDTRR9wdADjRTdf9hXkYPj1X78vlWr3GOVdKCZE0Pt7DKHX8h4fXfz+nsJIyihPKQhZGX5S323G302Xdgbi+iSanRf5sWGBA6TeuJWToCseG3gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec1913d42d8e8d8eaa115800bebf2282/e4706/ioBroker_Logitech_Harmony_05.avif 230w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/d1af7/ioBroker_Logitech_Harmony_05.avif 460w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/7f308/ioBroker_Logitech_Harmony_05.avif 920w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/50d16/ioBroker_Logitech_Harmony_05.avif 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec1913d42d8e8d8eaa115800bebf2282/a0b58/ioBroker_Logitech_Harmony_05.webp 230w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/bc10c/ioBroker_Logitech_Harmony_05.webp 460w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/966d8/ioBroker_Logitech_Harmony_05.webp 920w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/8c558/ioBroker_Logitech_Harmony_05.webp 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec1913d42d8e8d8eaa115800bebf2282/81c8e/ioBroker_Logitech_Harmony_05.png 230w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/08a84/ioBroker_Logitech_Harmony_05.png 460w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/c0255/ioBroker_Logitech_Harmony_05.png 920w", "/en/static/ec1913d42d8e8d8eaa115800bebf2282/dd104/ioBroker_Logitech_Harmony_05.png 1064w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec1913d42d8e8d8eaa115800bebf2282/c0255/ioBroker_Logitech_Harmony_05.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "910px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/c6bbc/ioBroker_Logitech_Harmony_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAuUlEQVQY05XPsQ6CMBAG4L4/AwyWsgLhHawo72CM0YDUsDLAwAClV85IMRoNol9uuuTP3U8sy4rjmHO+Xt+Hc757sRltPyRJ4jgO8ZgHAH3fy04auGQYBkSMoojQFVWgeqUAwGz1EgBARN/3CWNuVVVlWdZ1LTupf2DCQRAQ13Xbtm2aBof33+ZoracwpVQp1XajqbU0Fb54XsY/mXAYhsS27aIorjOEEHmei4c0u6RZtj8cT+eUMe8GJHay/HpX/gQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/e4706/ioBroker_Logitech_Harmony_06.avif 230w", "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/d1af7/ioBroker_Logitech_Harmony_06.avif 460w", "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/f36e1/ioBroker_Logitech_Harmony_06.avif 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/a0b58/ioBroker_Logitech_Harmony_06.webp 230w", "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/bc10c/ioBroker_Logitech_Harmony_06.webp 460w", "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/92674/ioBroker_Logitech_Harmony_06.webp 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/81c8e/ioBroker_Logitech_Harmony_06.png 230w", "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/08a84/ioBroker_Logitech_Harmony_06.png 460w", "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/c6bbc/ioBroker_Logitech_Harmony_06.png 910w"],
              "sizes": "(max-width: 910px) 100vw, 910px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/37e7eaa1cfcdef376ffc34b9e85a2450/c6bbc/ioBroker_Logitech_Harmony_06.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can now import the `}<a parentName="p" {...{
        "href": "#blockly-json-export"
      }}>{`JSON code from the end of this article`}</a>{` to add the following code blocks:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b42ff2f9aac2202cc9b636163340377/d93d9/ioBroker_Logitech_Harmony_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACB0lEQVQoz32S3W/SYBTG+1fuxnhhYqJX05gYoxdmumyagSR+scmHmlEYIuoKg4HO8TW6MkqBdawCBQoUBERK3Wagfd++BoZkcuFze85zfk+eHKyY8POxg9Jett8/HSqqBgFCqF3KHgds3WpzCICmaQghTdMgAENVlQhvp9qU5aY6PMGcyzbTXaN1wZJI84eCmOFrQkfe91HLt545nTtBittKZjyJbIQpQTi6guRfCIwACCHsvc7xesGyu8ukCyIr1DPlarnZ825RV1dcN566ry3aL+lMc0uv5vWbQ0WdRNA0OAqoYe4V+5v75lgse1T+ztUbOUEst6UwEdfdfrmx8cVP5gJ01p88jKR4ACAau89PjMguvd36wBIKM6lvNYYX6Hyl0OjRwYPnd4w+IprICSRX3MvxmULz3HBRmGvJYblnJkn2WGhx9QYriLWO7CHiVx7a5g3O64v4ZYN57pHppsEziT3GTsi5VJRl4ny+Vha7lVan2u52+mc0ebRq9b3zke4dxhGmbF+pzQgLIJwlb6+uuR/rCR1e4Bv1bq8lya2fJ8rfPmek/SvMhe++wEMmPLju2TdtR9b8IQuRCNH5aLpEczUAIIAQQg1AOE2LEIIQqqqKsZ/XOe+HfCB5dvr7YiPTvemTzJgVRcE+GT++NTjwJ/Y4U6CKJZKtiD/64/H/zIPBQJKkPwftNDoYRQpAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b42ff2f9aac2202cc9b636163340377/e4706/ioBroker_Logitech_Harmony_07.avif 230w", "/en/static/0b42ff2f9aac2202cc9b636163340377/d1af7/ioBroker_Logitech_Harmony_07.avif 460w", "/en/static/0b42ff2f9aac2202cc9b636163340377/7f308/ioBroker_Logitech_Harmony_07.avif 920w", "/en/static/0b42ff2f9aac2202cc9b636163340377/95942/ioBroker_Logitech_Harmony_07.avif 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b42ff2f9aac2202cc9b636163340377/a0b58/ioBroker_Logitech_Harmony_07.webp 230w", "/en/static/0b42ff2f9aac2202cc9b636163340377/bc10c/ioBroker_Logitech_Harmony_07.webp 460w", "/en/static/0b42ff2f9aac2202cc9b636163340377/966d8/ioBroker_Logitech_Harmony_07.webp 920w", "/en/static/0b42ff2f9aac2202cc9b636163340377/972ad/ioBroker_Logitech_Harmony_07.webp 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b42ff2f9aac2202cc9b636163340377/81c8e/ioBroker_Logitech_Harmony_07.png 230w", "/en/static/0b42ff2f9aac2202cc9b636163340377/08a84/ioBroker_Logitech_Harmony_07.png 460w", "/en/static/0b42ff2f9aac2202cc9b636163340377/c0255/ioBroker_Logitech_Harmony_07.png 920w", "/en/static/0b42ff2f9aac2202cc9b636163340377/d93d9/ioBroker_Logitech_Harmony_07.png 1243w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b42ff2f9aac2202cc9b636163340377/c0255/ioBroker_Logitech_Harmony_07.png",
              "alt": "Logitech Harmony MQTT Control with ioBroker",
              "title": "Logitech Harmony MQTT Control with ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "pan--tilt-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pan--tilt-your-camera",
        "aria-label": "pan  tilt your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pan & Tilt your Camera`}</h3>
    <p>{`This sequence listens to the `}<strong parentName="p">{`Up`}</strong>{`, `}<strong parentName="p">{`Right`}</strong>{`, `}<strong parentName="p">{`Down`}</strong>{` and `}<strong parentName="p">{`Left`}</strong>{` button and updates the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`according "One-Step" MQTT Topics`}</a>{` on your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/5a190/ioBroker_Logitech_Harmony_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADF0lEQVQ4y41Ta3PSCBTNP90vzo4zvqr1WR4VKY9SiAmUQiCAlhIe4RUoJEAKhRahFMVSy0LYqqFPUGtVUB4Jcagf3Jkd1z1z59xPZ+45c+8Fzs+crdc3uh+f8ANh2O0Nu9+GX74Oev3BiBsMuUnjeEEQxv+CIAhAGsFd12XUnDE+i+LXtP6bjwM3QEKEvKy9rbfevai9aX/4JAgCx/P/LJ6fyIGCPR68CZFic0RqjMmR0C0wOKWN3YJ7F5ofE34FgDbgCrFdNe9Ra5ygHldLUeUMopRYsVjBTRWdsZKJ2ETSKWc+YyLXUWLLEdtGQoXIxqsRxwMJEJdPW0AVBsmfasUWnWoZgn2wHn8SzmJkIZCuuOht77N8qFRYyRS8qUpgveqhK3S5wXE8QC/GsT8XSNFi9I7e98ejpM7DvDltMIf1/Vbn7JPwnwCKjmhoGoxLTBE5EpNZyhbit1F/ipOwb1bikGlxuQ5TQt55yGcNZi3+DBrKmfG00ZdVu1OaOIHS1GNqVe1OwZ4c7NvQYevP6wdAQuefnzKZFMtmmQO+bUQNeGRjh8hUgvR24lk1U26QW7VkdSdX3U1Vq9RWjS4x6efNVKnBnpwBa0skdnmBFBsjDwz4pbmMIXB83jvsnLNHnW+D4W9sF2yEfxqMiExhORKVo0Vz6H9mnhxJQu+bkTklUHgW8j4y4Ao9DmNJaCWhd6+BTkqznBTZotKAZyHqn3H5H6JJ1fKazJ5cCmx+6fWBhC4AXllEH9pRsdVwFXRAXqq4F9+ohulSqri7vcfmX+0XmWa5+Xf+r2Zx922pxm7tsTvNw8GIAzII6b6iJaVL0ZnF4GVVzhh83+13PnZbx+3P3d4Pe7/es43A78BhCRKcs4YVts2LzBzHjyc87l88Vn846o8uePCzxuMxsKpxTd2339XgD9ROkdop1rqU9qjSGlHZVhUoMWcjb5vC93wr6qD3nge7b1qVWqlZlBKZY5NV1dfKJEavxwvZaD4TzhWzleZBm2FPGbbNsCcM266zp8zR8f7RSePwuMG2mVanedBpsO1ef/gdlaAiGJQrA5sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c5fed26bea1ed9d71838d4d9b091d76/e4706/ioBroker_Logitech_Harmony_08.avif 230w", "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/d1af7/ioBroker_Logitech_Harmony_08.avif 460w", "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/c9875/ioBroker_Logitech_Harmony_08.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c5fed26bea1ed9d71838d4d9b091d76/a0b58/ioBroker_Logitech_Harmony_08.webp 230w", "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/bc10c/ioBroker_Logitech_Harmony_08.webp 460w", "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/d00b9/ioBroker_Logitech_Harmony_08.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c5fed26bea1ed9d71838d4d9b091d76/81c8e/ioBroker_Logitech_Harmony_08.png 230w", "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/08a84/ioBroker_Logitech_Harmony_08.png 460w", "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/5a190/ioBroker_Logitech_Harmony_08.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c5fed26bea1ed9d71838d4d9b091d76/5a190/ioBroker_Logitech_Harmony_08.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "schalten-sie-ihren-alarm-scharf-bevor-sie-ihr-haus-verlassen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#schalten-sie-ihren-alarm-scharf-bevor-sie-ihr-haus-verlassen",
        "aria-label": "schalten sie ihren alarm scharf bevor sie ihr haus verlassen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schalten Sie Ihren Alarm scharf, bevor Sie Ihr Haus verlassen`}</h3>
    <p>{`Diese Sequenz aktiviert/deaktiviert den Alarm auf Ihrer Kamera. Wenn sie scharf gestellt ist, behält Ihre Kamera Ihr Wohnzimmer im Auge. Wenn der Alarm deaktiviert wird, dreht sich die Kamera zur Seite und deaktiviert alle Bewegungserkennungsbereiche. `}<strong parentName="p">{`Hinweis`}</strong>{`: Wenn Sie den kamerainternen PIR-Bewegungssensor in Kombination mit diesen Erfassungsbereichen verwenden, müssen Sie den Sensor ebenfalls deaktivieren, damit dies funktioniert:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/89c086aff38f77b0410aa3f58ef90031/5a190/ioBroker_Logitech_Harmony_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADZ0lEQVQ4y4WS61MaZxyF/TvbcTotiiAGDbCwikZFI7jc9oooRO7L7rIXkJuQksYSh6BMxDiNtbZGSESMip1xmnCVDJFMP7X5zfn2zjPvmXnOwO3fzFl5/qys+1CZrd++aja6zXar2WrX6412u9Ptdu/++wa21llKqYsvmKKPlvmxx8/96eNq7ej0Yu/NX+eXN91ut/M/8Et3klfjsUeOsM4ZmV7Z86Xuf+t+63rwM5xdmnSa9aRR7zbpPTYsxGUKTCofSudD6V3P5o4vm6Vz265UjkoXmUzRHd95/Wel16jTGUjhnEhHjuExFcYBuKBE2HGIHDcGezExgwv+H+w2mW/1O+iJaJGRGtnBedK/WfgC3w2kMU4865PD4UmMm8K4KZzXEsK0TZjC+WlbZGQ5JHWuA7R7GPPKIGECDkuMrCue79dOY9zoAqnENgArBcC0BqbVVlptpQALBaKcFApJHE9UQbcY9crNYTUeHTPz3sRuH05hnHQxqCBiaoTRICENwgAWCrAElSZSg7DDBlpsd6oCHhHskUG8AolIINYV2+nDGZxVzHq1MD9vpXQws7QiGN1JyJ0wejdNvvTMWmKGYQxxVuvldc6nem9m1rnJP9/vwz+jiXWRiQdxDsDd388lHdHDs9rBcSX/+uiidltvtj7WGx8bvfzzqZ9Gs92Ht11xXgWn5uyxuZUogOyT6d5D567Van7bc9Yu4OCqXedZhbxOyM84ooWjd/mDt4U3pdzB219eHW8dHr44/v1Z8ejXvZNs8SRT+OP0vNZXlULZER05QUSVVloFMworPQEFejEGHpqYH5fIobW1hwHXoNElXebH4fBPjykmU+yPJI1zssWgioiCCDOJhHpBQ1osBCK0lhBGTeyoww1SgRHMN2HZUBPRBxbBm9z917NURyqwjZ5eJKSBGQCmASsNWCkNykmgLyNhPMOYR24KK9GI1Mi6419VPSV48XxAjm6AuDBpC08SAohxIMppUBbEw0MGWrzmVNEeEeKRGPgHFmFYz7hiXxeWMAeHwHX5MgMY/IDeD5qpGYKfJlgtzs6sRGQmbszlmuJ9ErxXW0PE5Fbhfts9+CS7v8VvvUzv5pK57diL/fxv5ep1qXJZqlyWz69L59elD1fvrq5Pq1elau39xU25Wru6ub2HPwPvWQfBaPhbVgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89c086aff38f77b0410aa3f58ef90031/e4706/ioBroker_Logitech_Harmony_09.avif 230w", "/en/static/89c086aff38f77b0410aa3f58ef90031/d1af7/ioBroker_Logitech_Harmony_09.avif 460w", "/en/static/89c086aff38f77b0410aa3f58ef90031/c9875/ioBroker_Logitech_Harmony_09.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/89c086aff38f77b0410aa3f58ef90031/a0b58/ioBroker_Logitech_Harmony_09.webp 230w", "/en/static/89c086aff38f77b0410aa3f58ef90031/bc10c/ioBroker_Logitech_Harmony_09.webp 460w", "/en/static/89c086aff38f77b0410aa3f58ef90031/d00b9/ioBroker_Logitech_Harmony_09.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/89c086aff38f77b0410aa3f58ef90031/81c8e/ioBroker_Logitech_Harmony_09.png 230w", "/en/static/89c086aff38f77b0410aa3f58ef90031/08a84/ioBroker_Logitech_Harmony_09.png 460w", "/en/static/89c086aff38f77b0410aa3f58ef90031/5a190/ioBroker_Logitech_Harmony_09.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/89c086aff38f77b0410aa3f58ef90031/5a190/ioBroker_Logitech_Harmony_09.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "arm-your-alarm-before-your-leave-your-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#arm-your-alarm-before-your-leave-your-home",
        "aria-label": "arm your alarm before your leave your home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Arm your Alarm before your leave your Home`}</h3>
    <p>{`This sequence defines an armed and disarmed state for your camera. When armed your camera will keep an eye on your living room. When disarmed your camera will turn away and deactivates all motion detection areas. `}<strong parentName="p">{`Note`}</strong>{` that if you are using the camera's internal PIR motion sensor in combination with those detection areas, you will have to deactivate the sensor as well for this to work:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2a4bbccf2802753e442137b41152bd04/abe2a/ioBroker_Logitech_Harmony_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABDUlEQVQY02XMTUvCYAAA4P3ILkHUqSACzRh03qRAiVkaTFmMcoULYk7XSlqB6GLMHXSfGZNWOnJ9rRUiRNtbdMt6fsADOTrqGIhn04H74g+8IBi9vo/9t9FnGIJoAvgHYlbTPLLFwGlmJcMuY3bb9scfw8fnMAJfkyLws4W/NigP4yRK7qWoApzDYxm5od54ftd50BzXdPva7UDvDVX7vuc+AfC3g2bQUizLJDYP4xidyB4tpfYX13YXkgdT6/j8zvb0RmEWoeeSpTjGsHVVkK840eREk23oitWH8rkyRfIUUS0SlSJR4c+VC8U6k6xjWRW0zomi1qSu0LquSRbX1E4vDU40q02jXNda5t036LXu+ABP6ZQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a4bbccf2802753e442137b41152bd04/e4706/ioBroker_Logitech_Harmony_10.avif 230w", "/en/static/2a4bbccf2802753e442137b41152bd04/d1af7/ioBroker_Logitech_Harmony_10.avif 460w", "/en/static/2a4bbccf2802753e442137b41152bd04/7f308/ioBroker_Logitech_Harmony_10.avif 920w", "/en/static/2a4bbccf2802753e442137b41152bd04/85e95/ioBroker_Logitech_Harmony_10.avif 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2a4bbccf2802753e442137b41152bd04/a0b58/ioBroker_Logitech_Harmony_10.webp 230w", "/en/static/2a4bbccf2802753e442137b41152bd04/bc10c/ioBroker_Logitech_Harmony_10.webp 460w", "/en/static/2a4bbccf2802753e442137b41152bd04/966d8/ioBroker_Logitech_Harmony_10.webp 920w", "/en/static/2a4bbccf2802753e442137b41152bd04/d7218/ioBroker_Logitech_Harmony_10.webp 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2a4bbccf2802753e442137b41152bd04/81c8e/ioBroker_Logitech_Harmony_10.png 230w", "/en/static/2a4bbccf2802753e442137b41152bd04/08a84/ioBroker_Logitech_Harmony_10.png 460w", "/en/static/2a4bbccf2802753e442137b41152bd04/c0255/ioBroker_Logitech_Harmony_10.png 920w", "/en/static/2a4bbccf2802753e442137b41152bd04/abe2a/ioBroker_Logitech_Harmony_10.png 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2a4bbccf2802753e442137b41152bd04/c0255/ioBroker_Logitech_Harmony_10.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "start-a-manual-video-recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#start-a-manual-video-recording",
        "aria-label": "start a manual video recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Start a Manual Video Recording`}</h3>
    <p>{`This sequence enables you to start a manual video recording when pressing the `}<strong parentName="p">{`Play`}</strong>{` button on your remote. `}<strong parentName="p">{`Note`}</strong>{` that the delay node is currently set to 30s - you can adjust the length of your according by changing this value.`}</p>
    <h3 {...{
      "id": "trigger-an-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#trigger-an-alarm",
        "aria-label": "trigger an alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Trigger an Alarm`}</h3>
    <p>{`And now to the simplest sequence of this flow. Pressing the `}<strong parentName="p">{`Info`}</strong>{` button will trigger an alarm on your camera. This event will trigger all active alarm actions on your camera - like vide recordings, email notifications, FTP uploads, etc.:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/5a190/ioBroker_Logitech_Harmony_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKUlEQVQY043Nz0vCUADAcf/bSLYki3Ig1cVfiaHQsGKiuOFslLo0QfLSW4fQS4fokLa5ZM7qPef0TdPlLO8Ffe8fvq7prDrERRMLi8XjcpWz/HeuenpTSq018+4HIdFgas10pZmqNLhaS+6rb8azqrfVnuMsnd9y5bzcxTZTorKCl+bJCE9EeCJcIGPKU8ec2tAYGyPrz7PopwV/Mk8d88GzYpRjN2IZd5QjY91WdzyzjZFl4AkaYWhiZFrItOAQwyGe218rfETE1wPn3gBPHXK7YdYXYn2h7E4ws09fHpyWPfGiJ5vZE3MEw24lytTJNZWs+uiKrMEVvudv6oVb6eoOiACUABAlIAJJlNov2mt/oGiw04fqO5L1D0VDHR2p+kDpocnn/Ad/A58dI33k7bPUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1f2644ae2e06e88dd7ef39d5282782a/e4706/ioBroker_Logitech_Harmony_11.avif 230w", "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/d1af7/ioBroker_Logitech_Harmony_11.avif 460w", "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/c9875/ioBroker_Logitech_Harmony_11.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b1f2644ae2e06e88dd7ef39d5282782a/a0b58/ioBroker_Logitech_Harmony_11.webp 230w", "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/bc10c/ioBroker_Logitech_Harmony_11.webp 460w", "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/d00b9/ioBroker_Logitech_Harmony_11.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b1f2644ae2e06e88dd7ef39d5282782a/81c8e/ioBroker_Logitech_Harmony_11.png 230w", "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/08a84/ioBroker_Logitech_Harmony_11.png 460w", "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/5a190/ioBroker_Logitech_Harmony_11.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b1f2644ae2e06e88dd7ef39d5282782a/5a190/ioBroker_Logitech_Harmony_11.png",
              "alt": "Logitech Harmony MQTT Control with Node-RED",
              "title": "Logitech Harmony MQTT Control with Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "conclusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#conclusion",
        "aria-label": "conclusion permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conclusion`}</h2>
    <p>{`This is just a small overview over all the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`available MQTT Topics`}</a>{` on your camera. All of them can be send and combined with a press of a button on your Logitech Harmony Remote. More information for using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"
      }}>{`INSTAR MQTT interface with ioBroker can be found in our full tutorial`}</a>{`.`}</p>
    <h2 {...{
      "id": "blockly-json-export",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blockly-json-export",
        "aria-label": "blockly json export permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Blockly JSON Export`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`<xml xmlns=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://www.w3.org/1999/xhtml"`}</span>{`>
  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"comment"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"8^U1_,dk,ef6^t^|JZ;+"`}</span>{` x=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-38"`}</span>{` y=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-113"`}</span>{`>
    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"COMMENT"`}</span>{`>Pan&amp;Tilt</field>
    <next>
      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"L[B8uguVD|3@=nC}NO~3"`}</span>{`>
        <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>ge</field>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
          <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qQHP{$c4InRp$o6vp@BB"`}</span>{`>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Up</field>
          </shadow>
        </value>
        <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"g@M?e*b1_8ezO{_(PUcP"`}</span>{`>
            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.ptz.movestep.raw</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"u2H{{k$7nO+({(J4,2,C"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>up</field>
              </block>
            </value>
          </block>
        </statement>
        <next>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"e}$7(hV3E%Bx9}kmKFca"`}</span>{`>
            <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>gt</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
              <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"@+%)|7?Tc?G=;j(\`i@PX"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Down</field>
              </shadow>
            </value>
            <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"d|K@:1dO!{[K{LTTP0mj"`}</span>{`>
                <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.ptz.movestep.raw</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2b.9ZC^jUqK49w{Advj="`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>down</field>
                  </block>
                </value>
              </block>
            </statement>
            <next>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"44Fs~A5pQ8,*n#TG]ogM"`}</span>{`>
                <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>gt</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
                  <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-nPyi);a$Bo}fA5$S.|w"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Left</field>
                  </shadow>
                </value>
                <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"jip7o1U:49HL|mJ}*\`;t"`}</span>{`>
                    <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.ptz.movestep.raw</field>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"~q*ow@aD\`6;OXnXp9_#]"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>left</field>
                      </block>
                    </value>
                  </block>
                </statement>
                <next>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"S%mT*RL0iur1u/~URa5T"`}</span>{`>
                    <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>gt</field>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
                      <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"QQ|X]mXDJ1nlt~X8X*I/"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Right</field>
                      </shadow>
                    </value>
                    <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"7hy/D$Kc;6J47RUilQXM"`}</span>{`>
                        <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.ptz.movestep.raw</field>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"#-yvwy4/W(Iv?-S9Mv^+"`}</span>{`>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>right</field>
                          </block>
                        </value>
                      </block>
                    </statement>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </next>
      </block>
    </next>
  </block>
  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"comment"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"!5:tmZx6,dztS)h%[*8u"`}</span>{` x=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"613"`}</span>{` y=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-113"`}</span>{`>
    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"COMMENT"`}</span>{`>Armed/Disarmed</field>
    <next>
      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"i!23)Yl3-5$$.sejbmp_"`}</span>{`>
        <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>gt</field>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
          <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tzCjnGT_Ez(9hR};yXOF"`}</span>{`>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Home</field>
          </shadow>
        </value>
        <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Vk{M_q)$w9t*Mz/zy2RA"`}</span>{`>
            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.ptz.preset.raw</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"A)LBw=FpoE6Y[vPX;O!!"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`</field>
              </block>
            </value>
            <next>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tS~GsJye~?T(JP;6Gb#i"`}</span>{`>
                <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area1.enable.raw</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mux/WG[~#y7c,dRz.r@0"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`</field>
                  </block>
                </value>
                <next>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"F5Nz|ow2PC3:9%QcrAu6"`}</span>{`>
                    <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area2.enable.raw</field>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"6a:*b1o-q.[T3d(pWU[r"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`</field>
                      </block>
                    </value>
                    <next>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qthuoOsu2lMx-+!J\`-b\`"`}</span>{`>
                        <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area3.enable.raw</field>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{()9t/y6#0Sm0OAKoC3T"`}</span>{`>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`</field>
                          </block>
                        </value>
                        <next>
                          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"s=Sq;fBh=fA8f[#:I-h}"`}</span>{`>
                            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area4.enable.raw</field>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"!1-dhH:@0^h?U[^FEV)D"`}</span>{`>
                                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`</field>
                              </block>
                            </value>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </next>
          </block>
        </statement>
        <next>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"KQOr\`fV9fb|5u$J#Afs|"`}</span>{`>
            <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>gt</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
              <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"B?-au^pP[51TLqvw:;3_"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Back</field>
              </shadow>
            </value>
            <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"tcQJu_~KY^*xk+LMclxe"`}</span>{`>
                <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.ptz.preset.raw</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"RUTkXYszfDj__Z_xLRP]"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`</field>
                  </block>
                </value>
                <next>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`".nqhS?=t]X?:Iw*N;yi8"`}</span>{`>
                    <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area1.enable.raw</field>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"H\`Irv[C~yr]D.(Dud,s!"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`</field>
                      </block>
                    </value>
                    <next>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{@T2R}:y[tVE3RmDJGyZ"`}</span>{`>
                        <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area2.enable.raw</field>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"f72fIjd@pgEmbpkN*wRD"`}</span>{`>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`</field>
                          </block>
                        </value>
                        <next>
                          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"X[d_,-{4-?HtN_u^.lpo"`}</span>{`>
                            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area3.enable.raw</field>
                            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Y@n%b]+$BX2m|?6H;i(*"`}</span>{`>
                                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`</field>
                              </block>
                            </value>
                            <next>
                              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"g6;}H)hk]Ln(@8fJMVFm"`}</span>{`>
                                <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.area4.enable.raw</field>
                                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=^gtJ-l7v-sKpl7r{:Js"`}</span>{`>
                                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`</field>
                                  </block>
                                </value>
                              </block>
                            </next>
                          </block>
                        </next>
                      </block>
                    </next>
                  </block>
                </next>
              </block>
            </statement>
          </block>
        </next>
      </block>
    </next>
  </block>
  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"comment"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Ej}EwLZxa*uxjJp@h\`x3"`}</span>{` x=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-37"`}</span>{` y=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"463"`}</span>{`>
    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"COMMENT"`}</span>{`>Manual Recording</field>
    <next>
      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"lT^wG1zJA[jTZ}seS1X?"`}</span>{`>
        <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>ge</field>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
          <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"$:sE0PF+cj*=@nWe$mDw"`}</span>{`>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Play</field>
          </shadow>
        </value>
        <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"k,*ua/iJiGU=kKMK9aNN"`}</span>{`>
            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.manualrec.start.raw</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{WUob~?\`ZYTi{y/a1!Zm"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`60`}</span>{`</field>
              </block>
            </value>
            <next>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=-sj.d3H/rmDw+{LNgp}"`}</span>{`>
                <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span>{`></mutation>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.features.manualrec.stop.raw</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>TRUE</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DELAY_MS"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`30`}</span>{`</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"UNIT"`}</span>{`>sec</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CLEAR_RUNNING"`}</span>{`>FALSE</field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"#q3EP{m}T6$=|Jh9dT*f"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>off</field>
                  </block>
                </value>
              </block>
            </next>
          </block>
        </statement>
      </block>
    </next>
  </block>
  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"comment"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"J]FEoC33xQY(@kf;+wMz"`}</span>{` x=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-38"`}</span>{` y=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"687"`}</span>{`>
    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"COMMENT"`}</span>{`>Trigger Alarm</field>
    <next>
      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Hdx}6@U[djCs$6ChcSO?"`}</span>{`>
        <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>ge</field>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
          <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"H$d@+hd{G*}azTCiGg%$"`}</span>{`>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>fakeroku.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.ioBroker.keys.Info</field>
          </shadow>
        </value>
        <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"_jUfMn2vQn#qO7bPWU2#"`}</span>{`>
            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.local.alarm.pushalarm.raw</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"32?hPl+U(C[i:2hzhWdP"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`</field>
              </block>
            </value>
          </block>
        </statement>
      </block>
    </next>
  </block>
</xml>`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      